import React, { useEffect } from 'react';
import SEO from '../components/seo';
import { Link } from 'gatsby';
import $ from 'jquery';

function FAQ ({ location }) {
    useEffect(() => {
        $('.list-faq', $('#faq')).each(function(){
            const list = $(this);
            const questions = list.find('dt');

            questions.on('click', function(e){
                e.preventDefault();
                const answer = $(this).next('dd');
                $(this).toggleClass('open');
                answer.slideToggle();
            });
        });
    }, [])

    return (        
        <React.Fragment>
            <SEO title="Frequently asked questions - Mike" url={location.pathname} />
            <article id="faq" className="page-content">
                <header className="text-center">
                    <h1>FAQ</h1>
                </header>                
                <div className="page-content__body">            
                    <div className="page-content__inner">   
                        <dl className="list-unstyled list-faq">
                            <dt>What is Mike?</dt>
                            <dd>
                                <p>Mike is a application built by <a href="https://www.loudup.com" target="_blank" rel="noreferrer noopener">LoudUp</a>. Our goal is to help you to take your music career to the next level by helping you to:</p>
                                <ul>
                                    <li>Set goals and track your progress</li>
                                    <li>Create tasks to ensure that you are moving forward towards achieving your goals</li>
                                    <li>Manage your income and expenses</li>
                                    <li>Create budgets and track how well you are managing your money</li>
                                    <li>Organise your schedule</li>
                                    <li>Monitor your social profiles</li>
                                    <li>Capture your ideas - you can store notes, audio recordings, photos, and documents</li>
                                </ul>
                            </dd>
                            <dt>How much does it cost to join?</dt>
                            <dd>
                                <p>You can view our pricing plans <a href="/#pricing">here</a>. Each subscription starts with a 1 month free trial.</p>
                            </dd>
                            <dt>Which devices are supported?</dt>
                            <dd>
                                <p>
                                    <strong>Web</strong>
                                    <br />
                                    The web application can be used on any device running a modern browser
                                </p>
                                <p>
                                    <strong>Android</strong>
                                    <br />
                                    The mobile app will run on Android versions 4.0.3+ (99.9% of Android devices as of April 2020). To check your Android version, go to Settings > About Phone, and look for the Android Version setting. We actively support Android versions 4.4+ (98% of Android devices). For versions 4.0.3 - 4.3, the app will run, but there may be some minor display issues.
                                </p>
                                <br />
                                <p>
                                    <strong>iOS</strong>
                                    <br />
                                    The mobile app will run on iOS version 9.0+ (99.4% of iOS devices as of April 2020), and will run on the following devices:
                                </p>    
                                <ul>
                                    <li>iPhone: 3GS or later, and iPhone SE</li>
                                    <li>iPad 1, 2, 3, iPad Mini, iPad Air, and iPad Pro</li>
                                    <li>iPod 3rd, 4th, and 5th generation</li>
                                </ul>                                
                            </dd>
                            <dt>How can I contact you?</dt>
                            <dd>
                                <p>Feel free to drop us an email at hello@mikeapp.co or via our <Link to="/contact">contact page</Link>.</p>
                            </dd>
                        </dl>                    
                    </div>
                </div>
            </article>  
        </React.Fragment>
    );
}

export default FAQ;